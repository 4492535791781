<template>
  <div style="height: 100%; overflow-y: auto" class="main-container">
    <header>
      <div class="left-side">
        <h1 style="font-weight: bold">Try the official BMS Apps</h1>
        <p>Integrate BMS directly into your workflow</p>
        <el-button icon="el-icon-bottom">See Apps Below</el-button>
      </div>
      <div class="right-side">
        <div class="grid-container">
          <div class="grid-item shopify">
            <el-card class="product-card">
              <img
                src="../../../assets/img/shopify_glyph.png"
                alt="Shopify logo"
                width="100" />
            </el-card>
          </div>
          <div class="grid-item woo-commerce">
            <el-card class="product-card" :body-style="{ padding: '0px' }">
              <img
                src="../../../assets/img/Woo-logo-color.png"
                alt="Woocommerce logo"
                width="200" />
            </el-card>
          </div>
          <div class="grid-item excel">
            <el-card class="product-card" shadow="never" style="border: none">
              <img
                src="../../../assets/img/Microsoft_Office_Excel.svg"
                alt="Woocommerce logo"
                width="100" />
            </el-card>
            <p class="product-text" style="margin: 0">
              Excel Integration <br />
              <small>Coming soon...</small>
            </p>
          </div>
        </div>
      </div>
    </header>
    <div class="main-container">
      <h5 style="margin-top: 2rem; font-weight: bold">Available Apps</h5>
      <el-divider></el-divider>
      <div class="products-container">
        <div
          class="grid-item-product"
          v-for="(product, index) in products"
          :key="index">
          <el-card class="available-card">
            <img :src="product.banner" alt="App banner" width="100" />
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
              class="card-content">
              <div style="margin-block: 1rem">
                <h5 class="available-product-text" style="margin-top: 0.8rem">
                  {{ product.title }}
                </h5>
                <div style="display: flex; gap: 1rem; flex-wrap: wrap">
                  <el-tag
                    v-for="(tag, index) in product.tags"
                    :key="index"
                    size="mini"
                    type="info"
                    >{{ tag }}</el-tag
                  >
                </div>
              </div>

              <a
                :href="product.link"
                style="text-decoration: none"
                target="_blank">
                <el-button
                  type="danger"
                  icon="el-icon-circle-plus"
                  size="small"
                  round
                  >Install Add-on</el-button
                >
              </a>
            </div>

            <el-collapse>
              <el-collapse-item
                :title="product.banner_title"
                name="1"
                v-if="product.title == 'BMS SMS Notification'">
                <div>
                  <p>
                    Introducing "mNotify BMS SMS Notification" - the unique
                    solution for simplified SMS communication. Easily send
                    customized SMS notifications to clients and yourself. Solve
                    the problem of limited SMS coverage in regions like Africa.
                    Expand your reach and connect with customers worldwide.
                    Enjoy seamless setup and usage. Craft personalized SMS
                    templates to deepen relationships, drive sales, and enhance
                    satisfaction. Experience the power of "mNotify BMS" to
                    streamline communication, overcome barriers.
                  </p>
                  <ul>
                    <li>Customized SMS templates.</li>
                    <li>Send messages on different events.</li>
                    <li>Seamless Setup and Usage.</li>
                  </ul>
                </div>
              </el-collapse-item>
              <el-collapse-item
                :title="product.banner_title"
                name="1"
                v-else-if="product.title == 'mNotifySMS WooCommerce'">
                <p>
                  Our extension will integrate SMS features into your store at
                  no charge. mNotifySMS allows you to simply notify the customer
                  through text messages with important order status information
                  from your store instantly. Configuration is very easy, no need
                  to setup any SMS gateway. Purchase SMS credits will be given
                  upon registration.
                </p>
                <br />
                <h5>Features:</h5>
                <ul>
                  <li>Notify seller whenever a new order is placed.</li>
                  <li>Inform buyer the current order status.</li>
                  <li>Support all woocomerce order statuses.</li>
                  <li>Customizable sms/message templates.</li>
                  <li>
                    These tags are supported to customize message: [shop_name],
                    [order_id], [order_amount], [order_status], [order_product],
                    [payment_method], [bank_details], [billing_first_name],
                    [billing_last_name], [billing_phone], [billing_email],
                    [billing_company], [billing_address], [billing_country],
                    [billing_city], [billing_state], [billing_postcode]
                  </li>
                  <li>
                    Custom checkout field added from Woo Checkout Field Editor
                    Pro is supported.
                  </li>
                </ul>
                <h5>How To Manually Install:</h5>
                <ul>
                  <li>Click on "install addon" to download &nbsp;<code> mnotify-bms-wordpress-v-2.0.1.zip </code></li>
                  <li>On the admin side bar, locate and click on plugins</li>
                  <li>Click on "Add New Plugin"</li>
                  <li>At the top click on "Upload Plugin"</li>
                  <li>Locate and choose the downloaded file &nbsp;<code> mnotify-bms-wordpress-v-2.0.1.zip </code></li>
                  <li>Click on "install now" and click on "Activate Now"</li>
                  <li>On the admin sidebar locate and click on "mNotify (BMS)"</li>
                  <li>Update the required fields, and click on Save Changes <strong>Only API v.2 is supported</strong> </li>
                  

                </ul>
              </el-collapse-item>
            </el-collapse>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ShopifySMS from "../../../assets/img/ShopifySMS.webp";
  import WooCommerceBanner from "../../../assets/img/banner-772x250.png";
  export default {
    name: "Market Place",
    data() {
      return {
        products: [
          {
            banner: ShopifySMS,
            title: "BMS SMS Notification",
            description: "",
            link: "https://apps.shopify.com/mnotify-bms-sms-notification?show_store_picker=1&st_source=autocomplete",
            banner_title:
              "Effortlessly create & send customized SMS to buyers using mNotify BMS SMS Notification",
            tags: ["Merchant Order Notification", "Customizable Templates"],
          },
          {
            banner: WooCommerceBanner,
            title: "mNotifySMS WooCommerce",
            description: "",
            link: "https://production.mnotify.com/storage/wordpress_plugin/mnotify-bms-wordpress-v-2.0.1.zip",
            banner_title:
              "Send SMS notification to both buyer and seller related to order status information in WooCommerce.",
            tags: ["Order Notification", "SMS"],
          },
        ],
      };
    },
  };
</script>

<style scoped>
  header {
    background-image: url("../../../assets/img/market_place_bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 25rem;
    width: 100%;
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .left-side {
    color: #fff;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 0.5rem;
  }

  .grid-item {
    height: 100%;
    max-width: 10rem;
    border-radius: 10px;
    display: flex;
    align-items: stretch;
  }

  .grid-item img {
    width: 100%;
    object-fit: contain;
    height: 100%;
  }

  .excel {
    grid-column: 1 / span 2;
    max-width: 100%;
    overflow: hidden;
    background-color: #00000024;
    backdrop-filter: blur(10px);
    display: flex;
    border: 1px solid #cccccc1f;
    align-items: center;
  }

  .excel img {
    max-width: 400px;
    max-height: 110px;
  }

  .product-card {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    background-color: #00000024;
    backdrop-filter: blur(10px);
    border: 1px solid #cccccc1f;
  }

  .woo-commerce img {
    width: 10rem;
  }

  .product-text {
    color: #fff;
    font-weight: bold;
    padding-left: 1rem;
  }

  .products-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    padding-bottom: 5rem;
  }

  .available-card img {
    width: 100%;
    max-height: 200px;
    object-fit: contain;
  }

  .el-collapse-item >>> * {
    color: #000;
  }

  .available-product-text {
    font-weight: bold;
  }

  .main-container {
    padding: 1rem;
  }

  @media screen and (max-width: 1024px) {
    header {
      height: 100%;
      flex-direction: column;
    }
    .main-container {
      padding: 0;
    }

    img {
      width: 100%;
    }

    .left-side {
      text-align: center;
    }

    .card-content {
      display: block;
      margin-bottom: 4rem;
    }
    .el-tag {
      margin-top: 0.5rem;
    }

    .el-collapse-item >>> .el-collapse-item__header {
      overflow-y: auto;
      min-height: 4rem;
      line-height: 1.5;
    }
  }
</style>
